interface Bindings{
	value : string
}

let mounted = (el : HTMLElement, bindings : Bindings) => {

	function getTop(){
		return el.getBoundingClientRect().top;
	}

	let top : number = getTop();

	function check(){

		let padding = window.innerWidth > 960
			? window.innerHeight * 0.35
			: -200;

		if(window.pageYOffset + window.innerHeight - padding >= top){

			el.classList.add(bindings.value);

			window.removeEventListener('resize', onResize);
			window.removeEventListener('scroll', onScroll);

		}

	}

	function onResize(){

		top = getTop();

		check();

	}

	function onScroll(){
		check();
	}

	window.addEventListener('resize', onResize);
	window.addEventListener('scroll', onScroll);

	check();

}

export const vScrollAnimate = {
	mounted
};
