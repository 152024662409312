<script lang="ts" setup>

import {Package} from "@/modules/api/models";
import {computed} from "vue";
import CalcCounter from "@/components/Calc/CalcCounter.vue";

let props = defineProps<{
	package : Package
}>();

let model = defineModel();

function monthsToString(n : number){

	if(n === 1){
		return `1 месяц`;
	}

	if(n >= 2 && n <= 4){
		return `${n} месяца`;
	}

	if(n >= 5 && n <= 11){
		return `${n} месяцев`;
	}

	if(n === 12){
		return `1 год`;
	}

	if(n === 24){
		return `2 года`;
	}

	if(n === 36){
		return `3 года`;
	}

	return `${n} мес.`;

}

</script>
<template>
	<tr class="calc-row">
		<td>{{ props.package.name }}</td>
		<td>{{ monthsToString(props.package.months) }}</td>
		<td>${{ props.package.price }}</td>
		<td>{{ props.package.percentage }}% годовых</td>
		<td style="text-align: center;">
			<CalcCounter v-model="model" />
		</td>
		<td class="calc-row__return">${{ (+model * (props.package.profit + props.package.price)).toFixed(2) }}</td>
	</tr>
</template>