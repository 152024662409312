import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "roadmap section section--mini" }
const _hoisted_2 = { class: "roadmap__body" }
const _hoisted_3 = { class: "roadmap-item__date" }
const _hoisted_4 = { class: "roadmap-item__title" }
const _hoisted_5 = {
  key: 0,
  class: "roadmap-item__description"
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Roadmap',
  setup(__props) {


let list : {
	date : string,
	title : string,
	description? : string,
	completed? : boolean
}[] = [
	{
		date : 'III квартал 2023',
		title : 'Покупка первых авто',
		description : 'Настройка логистических цепочек и бизнес-процессов проекта',
		completed : true
	},
	{
		date : 'I квартал 2024',
		title : 'Запуск в Санкт-Петербурге',
		description : 'На контракте с Яндекс.Такси свыше 100 собственных автомобилей',
		completed : true
	},
	{
		date : 'II квартал 2024',
		title : 'Разработка личного кабинета инвестора',
		description : 'Подготовка инфраструктуры для привлечения коллективных инвестиций в расширение парка',
		completed : true
	},
	{
		date : 'IV квартал 2024',
		title : 'Запуск в Москве',
		description : 'На контракте с Яндекс.Такси свыше 100 автомобилей в Москве и 200 в Санкт-Петербурге'
	},
	{
		date : 'III квартал 2025',
		title : 'Выход в регионы РФ',
		description : 'Открытие автопарков в Новосибирске, Нижнем Новгороде, Казани и Екатеринбурге'
	},
	{
		date : 'I квартал 2026',
		title : 'Выход в ЮАР',
		description : 'В составе инфраструктуры Яндекса открытие офиса в Кейптауне и расширение автопарка'
	},
	{
		date : 'IV квартал 2026',
		title : 'Выход на IPO на Московской бирже'
	}
];


return (_ctx: any,_cache: any) => {
  const _directive_scroll_animate = _resolveDirective("scroll-animate")!

  return _withDirectives((_openBlock(), _createElementBlock("section", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "roadmap__title" }, "Дорожная карта", -1)),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(list), (item, i) => {
        return (_openBlock(), _createElementBlock("article", {
          class: _normalizeClass(["roadmap-item roadmap__item", {
				'roadmap-item--final' : i === _unref(list).length - 1,
				'roadmap-item--disabled' : !item.completed
			}]),
          key: item.date
        }, [
          _createElementVNode("time", _hoisted_3, _toDisplayString(item.date), 1),
          _createElementVNode("h3", _hoisted_4, _toDisplayString(item.title), 1),
          (item.description)
            ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(item.description), 1))
            : _createCommentVNode("", true)
        ], 2))
      }), 128))
    ])
  ])), [
    [_directive_scroll_animate, 'roadmap--showed']
  ])
}
}

})