import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "calc" }
const _hoisted_2 = { class: "section section--mini" }
const _hoisted_3 = { class: "calc__table calc-table" }
const _hoisted_4 = { class: "calc__result" }
const _hoisted_5 = { class: "calc-car calc__car" }
const _hoisted_6 = { class: "calc-car__body" }
const _hoisted_7 = { class: "calc-car__percent" }

import {useApi} from "@/composibles/api";
import { Package } from "@/modules/api/models";
import {computed, onMounted, reactive, Ref, ref, watch} from "vue";
import CalcRow from "@/components/Calc/CalcRow.vue";
import CalcRowMobile from "@/components/Calc/CalcRowMobile.vue";

type CPackage = Package & {
	count : number
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Calc',
  setup(__props) {


const api = useApi();

let packages = reactive<CPackage[]>([]),
	loading = ref<boolean>(false);

let mobileCalc = ref<null | HTMLElement>(null);

let price = computed<number>(() => {
	return packages.reduce((sum, pack) => {
		return sum + (pack.price * pack.count);
	}, 0)
});

let share = computed<number>(() => {
	return (price.value / 12000) * 100;
});

let progress = computed<number>(() => {
	return share.value % 100;
});

onMounted(async () => {

	try{

		let list = await api.getPackages();

		packages.push(
			...list.map(pack => {
				return {
					...pack,
					count : 0
				}
			})
		);

	}catch (e){
		console.error(e);
	}

	loading.value = false;

});

watch(packages, () => {

	$(mobileCalc.value).slick('refresh');

});

onMounted(() => {

	$(mobileCalc.value).slick({
		dots : true,
		arrows : true,
		slidesToShow : 1
	});

});



return (_ctx: any,_cache: any) => {
  const _directive_scroll_animate = _resolveDirective("scroll-animate")!

  return _withDirectives((_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("h2", { class: "calc__title" }, "Калькулятор доходности", -1)),
      _createElementVNode("table", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _createElementVNode("th", null, "Пакет"),
            _createElementVNode("th", null, "Срок"),
            _createElementVNode("th", null, "Cумма"),
            _createElementVNode("th", null, "Доходность"),
            _createElementVNode("th", { style: {"text-align":"center"} }, "Количество пакетов"),
            _createElementVNode("th", null, "К получению")
          ])
        ], -1)),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(packages), (pack) => {
            return (_openBlock(), _createBlock(CalcRow, {
              class: "calc__row",
              key: pack.id,
              package: pack,
              modelValue: pack.count,
              "onUpdate:modelValue": ($event: any) => ((pack.count) = $event)
            }, null, 8, ["package", "modelValue", "onUpdate:modelValue"]))
          }), 128))
        ])
      ]),
      _createElementVNode("div", {
        class: "calc__mobile calc-mobile",
        ref_key: "mobileCalc",
        ref: mobileCalc
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(packages), (pack) => {
          return (_openBlock(), _createBlock(CalcRowMobile, {
            class: "calc-mobile__item",
            key: pack.id,
            package: pack,
            modelValue: pack.count,
            "onUpdate:modelValue": ($event: any) => ((pack.count) = $event)
          }, null, 8, ["package", "modelValue", "onUpdate:modelValue"]))
        }), 128))
      ], 512),
      _createElementVNode("div", _hoisted_4, [
        _cache[1] || (_cache[1] = _createTextVNode(" Итого ")),
        _createElementVNode("b", null, "$" + _toDisplayString(_unref(price).toFixed(0)), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", {
          class: _normalizeClass(["calc-car__bg", { 'calc-car__bg--active' : _unref(share) >= 100 }])
        }, null, 2),
        _createElementVNode("div", {
          class: "calc-car__progress",
          style: _normalizeStyle({ width : _unref(progress).toFixed(2) + '%' })
        }, null, 4),
        _createElementVNode("p", _hoisted_7, "Доля в автомобиле: " + _toDisplayString(_unref(share).toFixed(0)) + "%", 1)
      ])
    ])
  ])), [
    [_directive_scroll_animate, 'calc--showed']
  ])
}
}

})