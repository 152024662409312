<script setup lang="ts">

let list : {
	title : string,
	icon : string,
	description : string
}[] = [
	{
		title : 'Ежедневная выплата %',
		icon : 'wallet',
		description : 'Прибыль от автомобиля начисляется на ваш счет в Личном кабинете ежедневно равными долями и доступна для вывода в любой момент'
	},
	{
		title : 'Гарантированный доход',
		icon : 'calc',
		description : 'Благодаря большому потоку автомобилей мы можем зафиксировать стабильный доход для инвестора без привязки к состоянию конкретного авто'
	},
	{
		title : 'Выгодно для всех',
		icon : 'settings',
		description : 'Средняя доходность авто в аренде 70-90% годовых с учетом всех рисков, мы делимся доходностью с вами, зарабатываем сами и отвечаем за весь процесс'
	},
	{
		title : 'Распределенные риски',
		icon : 'shield',
		description : 'Мы распределяем риски поломки или простоя авто среди всех инвесторов, так, чтобы это не влияло на доходность вложений'
	}
];

</script>
<template>
	<section class="advantages section section--mini" v-scroll-animate="'advantages--showed'">
		<h2 class="advantages__title">С нами выгодно</h2>
		<p class="advantages__hint">В нашем автопарке уже <b>больше 150 автомобилей</b>, которые сданы в аренду <b>Я</b>ндекс.Такси и ежедневно приносят доход. Каждый день мы привозим и передаем в такси несколько новых автомобилей и Китая</p>
		<div class="advantages__body">
			<article class="advantages__item advantages-item" v-for="(item,i) in list" :key="item.title" :class="{ 'advantages-item--active' : (i === 0 || i === 3) }">
				<i class="advantages-item__icon">
					<i class="icon" :class="`icon--${item.icon}`"></i>
				</i>
				<h3 class="advantages-item__title">{{ item.title }}</h3>
				<p class="advantages-item__description">{{ item.description }}</p>
			</article>
		</div>
	</section>
</template>