<script lang="ts" setup>

import {Package} from "@/modules/api/models";
import {computed} from "vue";
import CalcCounter from "@/components/Calc/CalcCounter.vue";

let props = defineProps<{
	package : Package
}>();

let model = defineModel();

let profit = computed<number>(() => {
	return +(<string>model.value) * (props.package.price * (props.package.percentage / 100 + 1));
});

function monthsToString(n : number){

	if(n === 1){
		return `1 месяц`;
	}

	if(n >= 2 && n <= 4){
		return `${n} месяца`;
	}

	if(n >= 5 && n <= 11){
		return `${n} месяцев`;
	}

	if(n === 12){
		return `1 год`;
	}

	if(n === 24){
		return `2 года`;
	}

	if(n === 36){
		return `3 года`;
	}

	return `${n} мес.`;

}

</script>
<template>
	<div class="calc-mobile-item" :key="props.package.id">
		<p class="calc-mobile-item__name">{{ props.package.name }}</p>
		<div class="calc-mobile-item__row">
			<span class="calc-mobile-item__row-title">Срок</span>
			<span class="calc-mobile-item__row-value">{{ monthsToString(props.package.months) }}</span>
		</div>
		<div class="calc-mobile-item__row">
			<span class="calc-mobile-item__row-title">Сумма</span>
			<span class="calc-mobile-item__row-value">${{ props.package.price }}</span>
		</div>
		<div class="calc-mobile-item__row">
			<span class="calc-mobile-item__row-title">Доходность</span>
			<span class="calc-mobile-item__row-value">{{ props.package.percentage }}% годовых</span>
		</div>
		<div class="calc-mobile-item__row">
			<span class="calc-mobile-item__row-title">Количество пакетов</span>
			<span class="calc-mobile-item__row-value">
				<CalcCounter v-model="model" />
			</span>
		</div>
		<div class="calc-mobile-item__row">
			<span class="calc-mobile-item__row-title">К получению</span>
			<span class="calc-mobile-item__row-value">$ {{ (+model * (props.package.profit + props.package.price)).toFixed(2) }}</span>
		</div>
	</div>
</template>