<script setup lang="ts">

import Button from "@/components/View/Button.vue";

let cabinetUrl = process.env.VUE_APP_CABINET_URL;

</script>
<template>
	<section class="intro">
		<div class="section section--mini">
			<div class="intro__body">
				<h1 class="intro__title">Получи долю <br> в доходных автомобилях <br> <b>Я</b>ндекс.Такси</h1>
				<ul class="intro__list">
					<li class="intro__list-item">Минимальная сумма от $500</li>
					<li class="intro__list-item">Ежедневное начисление %</li>
					<li class="intro__list-item intro__list-item--small">От 30% до 50% годовых</li>
					<li class="intro__list-item intro__list-item--small">Выплаты с первого дня</li>
				</ul>
				<Button :href="cabinetUrl" class="intro__button">Начать инвестировать</Button>
			</div>
		</div>
	</section>
</template>
