<script lang="ts" setup>

import {useApi} from "@/composibles/api";
import { Package } from "@/modules/api/models";
import {computed, onMounted, reactive, Ref, ref, watch} from "vue";
import CalcRow from "@/components/Calc/CalcRow.vue";
import CalcRowMobile from "@/components/Calc/CalcRowMobile.vue";

type CPackage = Package & {
	count : number
}

const api = useApi();

let packages = reactive<CPackage[]>([]),
	loading = ref<boolean>(false);

let mobileCalc = ref<null | HTMLElement>(null);

let price = computed<number>(() => {
	return packages.reduce((sum, pack) => {
		return sum + (pack.price * pack.count);
	}, 0)
});

let share = computed<number>(() => {
	return (price.value / 12000) * 100;
});

let progress = computed<number>(() => {
	return share.value % 100;
});

onMounted(async () => {

	try{

		let list = await api.getPackages();

		packages.push(
			...list.map(pack => {
				return {
					...pack,
					count : 0
				}
			})
		);

	}catch (e){
		console.error(e);
	}

	loading.value = false;

});

watch(packages, () => {

	$(mobileCalc.value).slick('refresh');

});

onMounted(() => {

	$(mobileCalc.value).slick({
		dots : true,
		arrows : true,
		slidesToShow : 1
	});

});


</script>
<template>
	<main class="calc" v-scroll-animate="'calc--showed'">
		<div class="section section--mini">
			<h2 class="calc__title">Калькулятор доходности</h2>
			<table class="calc__table calc-table">
				<thead>
					<tr>
						<th>Пакет</th>
						<th>Срок</th>
						<th>Cумма</th>
						<th>Доходность</th>
						<th style="text-align: center">Количество пакетов</th>
						<th>К получению</th>
					</tr>
				</thead>
				<tbody>
					<CalcRow class="calc__row" v-for="pack in packages" :key="pack.id" :package="pack" v-model="pack.count" />
				</tbody>
			</table>
			<div class="calc__mobile calc-mobile" ref="mobileCalc">
				<CalcRowMobile class="calc-mobile__item" v-for="pack in packages" :key="pack.id" :package="pack" v-model="pack.count" />
			</div>
			<div class="calc__result">
				Итого <b>${{ price.toFixed(0) }}</b>
			</div>
		</div>
		<div class="calc-car calc__car">
			<div class="calc-car__body">
				<div class="calc-car__bg" :class="{ 'calc-car__bg--active' : share >= 100 }"></div>
				<div class="calc-car__progress" :style="{ width : progress.toFixed(2) + '%' }"></div>
				<p class="calc-car__percent" >Доля в автомобиле: {{ share.toFixed(0) }}%</p>
			</div>
		</div>
	</main>
</template>