import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "advantages section section--mini" }
const _hoisted_2 = { class: "advantages__body" }
const _hoisted_3 = { class: "advantages-item__icon" }
const _hoisted_4 = { class: "advantages-item__title" }
const _hoisted_5 = { class: "advantages-item__description" }


export default /*@__PURE__*/_defineComponent({
  __name: 'Advantages',
  setup(__props) {


let list : {
	title : string,
	icon : string,
	description : string
}[] = [
	{
		title : 'Ежедневная выплата %',
		icon : 'wallet',
		description : 'Прибыль от автомобиля начисляется на ваш счет в Личном кабинете ежедневно равными долями и доступна для вывода в любой момент'
	},
	{
		title : 'Гарантированный доход',
		icon : 'calc',
		description : 'Благодаря большому потоку автомобилей мы можем зафиксировать стабильный доход для инвестора без привязки к состоянию конкретного авто'
	},
	{
		title : 'Выгодно для всех',
		icon : 'settings',
		description : 'Средняя доходность авто в аренде 70-90% годовых с учетом всех рисков, мы делимся доходностью с вами, зарабатываем сами и отвечаем за весь процесс'
	},
	{
		title : 'Распределенные риски',
		icon : 'shield',
		description : 'Мы распределяем риски поломки или простоя авто среди всех инвесторов, так, чтобы это не влияло на доходность вложений'
	}
];


return (_ctx: any,_cache: any) => {
  const _directive_scroll_animate = _resolveDirective("scroll-animate")!

  return _withDirectives((_openBlock(), _createElementBlock("section", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "advantages__title" }, "С нами выгодно", -1)),
    _cache[1] || (_cache[1] = _createElementVNode("p", { class: "advantages__hint" }, [
      _createTextVNode("В нашем автопарке уже "),
      _createElementVNode("b", null, "больше 150 автомобилей"),
      _createTextVNode(", которые сданы в аренду "),
      _createElementVNode("b", null, "Я"),
      _createTextVNode("ндекс.Такси и ежедневно приносят доход. Каждый день мы привозим и передаем в такси несколько новых автомобилей и Китая")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(list), (item, i) => {
        return (_openBlock(), _createElementBlock("article", {
          class: _normalizeClass(["advantages__item advantages-item", { 'advantages-item--active' : (i === 0 || i === 3) }]),
          key: item.title
        }, [
          _createElementVNode("i", _hoisted_3, [
            _createElementVNode("i", {
              class: _normalizeClass(["icon", `icon--${item.icon}`])
            }, null, 2)
          ]),
          _createElementVNode("h3", _hoisted_4, _toDisplayString(item.title), 1),
          _createElementVNode("p", _hoisted_5, _toDisplayString(item.description), 1)
        ], 2))
      }), 128))
    ])
  ])), [
    [_directive_scroll_animate, 'advantages--showed']
  ])
}
}

})