import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, isRef as _isRef, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "calc-mobile-item__name" }
const _hoisted_2 = { class: "calc-mobile-item__row" }
const _hoisted_3 = { class: "calc-mobile-item__row-value" }
const _hoisted_4 = { class: "calc-mobile-item__row" }
const _hoisted_5 = { class: "calc-mobile-item__row-value" }
const _hoisted_6 = { class: "calc-mobile-item__row" }
const _hoisted_7 = { class: "calc-mobile-item__row-value" }
const _hoisted_8 = { class: "calc-mobile-item__row" }
const _hoisted_9 = { class: "calc-mobile-item__row-value" }
const _hoisted_10 = { class: "calc-mobile-item__row" }
const _hoisted_11 = { class: "calc-mobile-item__row-value" }

import {Package} from "@/modules/api/models";
import {computed} from "vue";
import CalcCounter from "@/components/Calc/CalcCounter.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CalcRowMobile',
  props: /*@__PURE__*/_mergeModels({
    package: {}
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {


let props = __props;

let model = _useModel(__props, "modelValue");

let profit = computed<number>(() => {
	return +(<string>model.value) * (props.package.price * (props.package.percentage / 100 + 1));
});

function monthsToString(n : number){

	if(n === 1){
		return `1 месяц`;
	}

	if(n >= 2 && n <= 4){
		return `${n} месяца`;
	}

	if(n >= 5 && n <= 11){
		return `${n} месяцев`;
	}

	if(n === 12){
		return `1 год`;
	}

	if(n === 24){
		return `2 года`;
	}

	if(n === 36){
		return `3 года`;
	}

	return `${n} мес.`;

}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "calc-mobile-item",
    key: _unref(props).package.id
  }, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(_unref(props).package.name), 1),
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("span", { class: "calc-mobile-item__row-title" }, "Срок", -1)),
      _createElementVNode("span", _hoisted_3, _toDisplayString(monthsToString(_unref(props).package.months)), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[2] || (_cache[2] = _createElementVNode("span", { class: "calc-mobile-item__row-title" }, "Сумма", -1)),
      _createElementVNode("span", _hoisted_5, "$" + _toDisplayString(_unref(props).package.price), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _cache[3] || (_cache[3] = _createElementVNode("span", { class: "calc-mobile-item__row-title" }, "Доходность", -1)),
      _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(props).package.percentage) + "% годовых", 1)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _cache[4] || (_cache[4] = _createElementVNode("span", { class: "calc-mobile-item__row-title" }, "Количество пакетов", -1)),
      _createElementVNode("span", _hoisted_9, [
        _createVNode(CalcCounter, {
          modelValue: _unref(model),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event))
        }, null, 8, ["modelValue"])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _cache[5] || (_cache[5] = _createElementVNode("span", { class: "calc-mobile-item__row-title" }, "К получению", -1)),
      _createElementVNode("span", _hoisted_11, "$ " + _toDisplayString((+_unref(model) * (_unref(props).package.profit + _unref(props).package.price)).toFixed(2)), 1)
    ])
  ]))
}
}

})