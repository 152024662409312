import ApiError from "@/modules/api/ApiError";
import {AxiosError} from "axios";

export default class ApiAxiosError extends Error
{

	public originalError : AxiosError;

	public constructor(e : AxiosError) {

		super('Network error');

		this.originalError = e;

	}

}