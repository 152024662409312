<script setup lang="ts">
import Header from "@/components/View/Header.vue";
import Footer from "@/components/View/Footer.vue";

require("../public/assets/css/normalize.css");
require("../public/assets/css/style.css");

</script>
<template>
    <router-view/>
</template>
