import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "how-it-work" }
const _hoisted_2 = { class: "section section--mini how-it-work__wrap" }
const _hoisted_3 = { class: "how-it-work__body" }
const _hoisted_4 = { class: "how-it-work-item__number" }
const _hoisted_5 = { class: "how-it-work-item__title" }
const _hoisted_6 = {
  key: 0,
  class: "how-it-work-item__description"
}
const _hoisted_7 = { class: "how-it-work__footer" }

import Button from "@/components/View/Button.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'HowItWork',
  setup(__props) {


let list : {
	title : string,
	description? : string
}[] = [
	{
		title : 'Вы приобретаете долю в автомобиле'
	},
	{
		title : 'Собираем всю сумму на покупку авто',
		description : 'Обычно в течение часа-полутора'
	},
	{
		title : 'Покупка, доставка и регистрация авто',
		description : 'Выкупаем один из автомобилей, которые уже на пути в РФ'
	},
	{
		title : 'Сдача автомобиля в аренду Яндекс.Такси',
		description : 'Передаем автомобиль в такси и получаем пассивный доход'
	}
];

let cabinetUrl = process.env.VUE_APP_CABINET_URL;


return (_ctx: any,_cache: any) => {
  const _directive_scroll_animate = _resolveDirective("scroll-animate")!

  return _withDirectives((_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "how-it-work__title" }, "Как это работает?", -1)),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(list), (item, i) => {
          return (_openBlock(), _createElementBlock("article", {
            class: _normalizeClass(["how-it-work__item how-it-work-item", { 'how-it-work-item--active' : i === 0 }]),
            key: item.title
          }, [
            _createElementVNode("i", _hoisted_4, _toDisplayString(i + 1), 1),
            _createElementVNode("h3", _hoisted_5, _toDisplayString(item.title), 1),
            (item.description)
              ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(item.description), 1))
              : _createCommentVNode("", true)
          ], 2))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(Button, {
          href: _unref(cabinetUrl),
          class: "how-it-work__button"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Начать инвестировать")
          ])),
          _: 1
        }, 8, ["href"])
      ])
    ])
  ])), [
    [_directive_scroll_animate, 'how-it-work--showed']
  ])
}
}

})