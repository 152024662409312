import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, isRef as _isRef, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "calc-row" }
const _hoisted_2 = { style: {"text-align":"center"} }
const _hoisted_3 = { class: "calc-row__return" }

import {Package} from "@/modules/api/models";
import {computed} from "vue";
import CalcCounter from "@/components/Calc/CalcCounter.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CalcRow',
  props: /*@__PURE__*/_mergeModels({
    package: {}
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {


let props = __props;

let model = _useModel(__props, "modelValue");

function monthsToString(n : number){

	if(n === 1){
		return `1 месяц`;
	}

	if(n >= 2 && n <= 4){
		return `${n} месяца`;
	}

	if(n >= 5 && n <= 11){
		return `${n} месяцев`;
	}

	if(n === 12){
		return `1 год`;
	}

	if(n === 24){
		return `2 года`;
	}

	if(n === 36){
		return `3 года`;
	}

	return `${n} мес.`;

}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
    _createElementVNode("td", null, _toDisplayString(_unref(props).package.name), 1),
    _createElementVNode("td", null, _toDisplayString(monthsToString(_unref(props).package.months)), 1),
    _createElementVNode("td", null, "$" + _toDisplayString(_unref(props).package.price), 1),
    _createElementVNode("td", null, _toDisplayString(_unref(props).package.percentage) + "% годовых", 1),
    _createElementVNode("td", _hoisted_2, [
      _createVNode(CalcCounter, {
        modelValue: _unref(model),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event))
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("td", _hoisted_3, "$" + _toDisplayString((+_unref(model) * (_unref(props).package.profit + _unref(props).package.price)).toFixed(2)), 1)
  ]))
}
}

})