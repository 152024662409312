<script setup lang="ts">


</script>
<template>
	<footer class="footer">
		<div class="section section--mini footer__body">
			<div class="footer__col">
				<p class="footer__title">Остались вопросы?</p>
				<p class="footer__hint">Пишите или звоните</p>
			</div>
			<div class="footer__col">
				<p class="footer__subtitle">Наш телефон:</p>
				<a href="tel:+78001234567" class="footer__phone">8 (800) 123-45-67</a>
			</div>
			<div class="footer__col">
				<p class="footer__subtitle">Наш E-mail:</p>
				<a href="mailto:info@info.com" class="footer__phone">info@info.com</a>
			</div>
			<div class="footer__col">
				<p class="footer__subtitle">Быстрая связь</p>
				<nav class="footer__socials">
					<a href="" class="footer__socials-item" target="_blank">
						<i class="icon icon--vk"></i>
					</a>
					<a href="" class="footer__socials-item" target="_blank">
						<i class="icon icon--tg"></i>
					</a>
					<a href="" class="footer__socials-item" target="_blank">
						<i class="icon icon--ok"></i>
					</a>
				</nav>
			</div>
		</div>
	</footer>
</template>
