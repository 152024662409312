import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import Intro from "@/components/Intro/Intro.vue";
import Calc from "@/components/Calc/Calc.vue";
import Advantages from "@/components/Advantages/Advantages.vue";
import HowItWork from "@/components/HowItWork/HowItWork.vue";
import Roadmap from "@/components/Roadmap/Roadmap.vue";
import Slider from "@/components/Slider/Slider.vue";
import Header from "@/components/View/Header.vue";
import Footer from "@/components/View/Footer.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(Header),
    _createVNode(Intro),
    _createVNode(Calc),
    _createVNode(HowItWork),
    _createVNode(Advantages),
    _createVNode(Roadmap),
    _createVNode(Footer)
  ], 64))
}
}

})