import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import RoadmapView from "@/views/RoadmapView.vue";
import CalcView from "@/views/CalcView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: HomeView
  },
  {
    path: '/roadmap',
    component: RoadmapView
  },
  {
    path: '/calc',
    component: CalcView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
