import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "intro" }
const _hoisted_2 = { class: "section section--mini" }
const _hoisted_3 = { class: "intro__body" }

import Button from "@/components/View/Button.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Intro',
  setup(__props) {


let cabinetUrl = process.env.VUE_APP_CABINET_URL;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createStaticVNode("<h1 class=\"intro__title\">Получи долю <br> в доходных автомобилях <br> <b>Я</b>ндекс.Такси</h1><ul class=\"intro__list\"><li class=\"intro__list-item\">Минимальная сумма от $500</li><li class=\"intro__list-item\">Ежедневное начисление %</li><li class=\"intro__list-item intro__list-item--small\">От 30% до 50% годовых</li><li class=\"intro__list-item intro__list-item--small\">Выплаты с первого дня</li></ul>", 2)),
        _createVNode(Button, {
          href: _unref(cabinetUrl),
          class: "intro__button"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Начать инвестировать")
          ])),
          _: 1
        }, 8, ["href"])
      ])
    ])
  ]))
}
}

})