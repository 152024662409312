<script setup lang="ts">

import Button from "@/components/View/Button.vue";

let list : {
	title : string,
	description? : string
}[] = [
	{
		title : 'Вы приобретаете долю в автомобиле'
	},
	{
		title : 'Собираем всю сумму на покупку авто',
		description : 'Обычно в течение часа-полутора'
	},
	{
		title : 'Покупка, доставка и регистрация авто',
		description : 'Выкупаем один из автомобилей, которые уже на пути в РФ'
	},
	{
		title : 'Сдача автомобиля в аренду Яндекс.Такси',
		description : 'Передаем автомобиль в такси и получаем пассивный доход'
	}
];

let cabinetUrl = process.env.VUE_APP_CABINET_URL;

</script>
<template>
	<section class="how-it-work" v-scroll-animate="'how-it-work--showed'">
		<div class="section section--mini how-it-work__wrap">
			<h2 class="how-it-work__title">Как это работает?</h2>
			<div class="how-it-work__body">
				<article class="how-it-work__item how-it-work-item" v-for="(item,i) in list" :key="item.title" :class="{ 'how-it-work-item--active' : i === 0 }">
					<i class="how-it-work-item__number">{{ i + 1 }}</i>
					<h3 class="how-it-work-item__title">{{ item.title }}</h3>
					<p class="how-it-work-item__description" v-if="item.description">{{ item.description }}</p>
				</article>
			</div>
			<div class="how-it-work__footer">
				<Button :href="cabinetUrl" class="how-it-work__button">Начать инвестировать</Button>
			</div>
		</div>
	</section>
</template>