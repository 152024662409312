import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "header__body section section--mini" }
const _hoisted_3 = { class: "header__tools" }

import Button from "@/components/View/Button.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Header',
  setup(__props) {


let cabinetUrl = process.env.VUE_APP_CABINET_URL;


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: "/",
        class: "header__logo"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("img", {
            src: `assets/images/develop/logo.svg`,
            alt: "",
            style: {"width":"100%"},
            class: "header__logo-picture"
          }, null, -1),
          _createElementVNode("span", { class: "header__logo-hint" }, "Автомобили", -1)
        ])),
        _: 1
      }),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(Button, {
          href: _unref(cabinetUrl),
          class: "header__button",
          size: "small"
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createElementVNode("i", { class: "icon icon--person" }, null, -1),
            _createElementVNode("span", null, "Личный кабинет", -1)
          ])),
          _: 1
        }, 8, ["href"])
      ])
    ])
  ]))
}
}

})