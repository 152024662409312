<script lang="ts" setup>

let list : {
	date : string,
	title : string,
	description? : string,
	completed? : boolean
}[] = [
	{
		date : 'III квартал 2023',
		title : 'Покупка первых авто',
		description : 'Настройка логистических цепочек и бизнес-процессов проекта',
		completed : true
	},
	{
		date : 'I квартал 2024',
		title : 'Запуск в Санкт-Петербурге',
		description : 'На контракте с Яндекс.Такси свыше 100 собственных автомобилей',
		completed : true
	},
	{
		date : 'II квартал 2024',
		title : 'Разработка личного кабинета инвестора',
		description : 'Подготовка инфраструктуры для привлечения коллективных инвестиций в расширение парка',
		completed : true
	},
	{
		date : 'IV квартал 2024',
		title : 'Запуск в Москве',
		description : 'На контракте с Яндекс.Такси свыше 100 автомобилей в Москве и 200 в Санкт-Петербурге'
	},
	{
		date : 'III квартал 2025',
		title : 'Выход в регионы РФ',
		description : 'Открытие автопарков в Новосибирске, Нижнем Новгороде, Казани и Екатеринбурге'
	},
	{
		date : 'I квартал 2026',
		title : 'Выход в ЮАР',
		description : 'В составе инфраструктуры Яндекса открытие офиса в Кейптауне и расширение автопарка'
	},
	{
		date : 'IV квартал 2026',
		title : 'Выход на IPO на Московской бирже'
	}
];

</script>
<template>
	<section class="roadmap section section--mini" v-scroll-animate="'roadmap--showed'">
		<h2 class="roadmap__title">Дорожная карта</h2>
		<div class="roadmap__body">
			<article class="roadmap-item roadmap__item" v-for="(item,i) in list" :key="item.date" :class="{
				'roadmap-item--final' : i === list.length - 1,
				'roadmap-item--disabled' : !item.completed
			}">
				<time class="roadmap-item__date">{{ item.date }}</time>
				<h3 class="roadmap-item__title">{{ item.title }}</h3>
				<p class="roadmap-item__description" v-if="item.description">{{ item.description }}</p>
			</article>
		</div>
	</section>
</template>