<script lang="ts" setup>

import {ref, watch} from "vue";

let model = defineModel();

let value = ref<string>('');

function changeValue(n : number){
	value.value = (+value.value + n).toString();
}

watch(value, v => {

	let m = parseInt(v) || 0;
	m = Math.min(m, 999)
	m = Math.max(0, m);

	model.value = m.toString();
	value.value = m.toString();

});

watch(model, v => {
	value.value = <string>v;
}, { immediate : true });

</script>
<template>
	<div class="calc-input">
		<button class="calc-input__button calc-input__button--prev" @click.prevent="changeValue(-1)"></button>
		<input type="text" class="calc-input__input calc-row__count" v-model="value" autocomplete="off" maxlength="3">
		<button class="calc-input__button calc-input__button--next" @click.prevent="changeValue(1)"></button>
	</div>
</template>