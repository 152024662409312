import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, unref as _unref, isRef as _isRef, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "calc-input" }

import {ref, watch} from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CalcCounter',
  props: {
    "modelValue": {},
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props) {


let model = _useModel(__props, "modelValue");

let value = ref<string>('');

function changeValue(n : number){
	value.value = (+value.value + n).toString();
}

watch(value, v => {

	let m = parseInt(v) || 0;
	m = Math.min(m, 999)
	m = Math.max(0, m);

	model.value = m.toString();
	value.value = m.toString();

});

watch(model, v => {
	value.value = <string>v;
}, { immediate : true });


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "calc-input__button calc-input__button--prev",
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (changeValue(-1)), ["prevent"]))
    }),
    _withDirectives(_createElementVNode("input", {
      type: "text",
      class: "calc-input__input calc-row__count",
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(value) ? (value).value = $event : value = $event)),
      autocomplete: "off",
      maxlength: "3"
    }, null, 512), [
      [_vModelText, _unref(value)]
    ]),
    _createElementVNode("button", {
      class: "calc-input__button calc-input__button--next",
      onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (changeValue(1)), ["prevent"]))
    })
  ]))
}
}

})