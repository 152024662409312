import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import Header from "@/components/View/Header.vue";
import Footer from "@/components/View/Footer.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

require("../public/assets/css/normalize.css");
require("../public/assets/css/style.css");


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_component_router_view))
}
}

})