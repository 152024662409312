<script setup lang="ts">

import Button from "@/components/View/Button.vue";

let cabinetUrl = process.env.VUE_APP_CABINET_URL;

</script>
<template>
	<header class="header">
		<div class="header__body section section--mini">
			<router-link to="/" class="header__logo">
				<img :src="`assets/images/develop/logo.svg`" alt="" style="width: 100%" class="header__logo-picture">
				<span class="header__logo-hint">Автомобили</span>
			</router-link>
			<div class="header__tools">
				<Button :href="cabinetUrl" class="header__button" size="small">
					<i class="icon icon--person"></i>
					<span>Личный кабинет</span>
				</Button>
			</div>
		</div>
	</header>
</template>
