<script lang="ts" setup>

let props = withDefaults(defineProps<{
	is : string,
	size : 'normal' | 'big' | 'small'
}>(), {
	is : 'a',
	size : 'normal'
});

</script>
<template>
	<component :is="props.is" class="button" :class="`button--${props.size}`">
		<span class="button__text">
			<slot></slot>
		</span>
	</component>
</template>