import axios, {AxiosError} from "axios";
import ApiAxiosError from "@/modules/api/ApiAxiosError";
import ApiError from "@/modules/api/ApiError";
import {Package} from "@/modules/api/models";

interface ApiQuery extends Record<string, any>{
}

interface ApiSuccessResponse{
	status : true,
	response : any
}

interface ApiErrorResponse{
	status : false,
	error : string,
	code : number
}

type ApiResponse = ApiSuccessResponse | ApiErrorResponse;

export default class Api{

	protected async request<T extends any>(method : string, data : ApiQuery = {}) : Promise<T>
	{

		try {

			let response = await axios.post(
				process.env.VUE_APP_API_URL + '/api/landing/' + method,
				data
			);

			let responseData : ApiResponse = response.data;

			if (responseData.status) {
				return responseData.response;
			}

			throw new ApiError(responseData.error, responseData.code);

		}catch (e){

			if(e instanceof AxiosError && e.message === 'Request aborted'){
				return new Promise(() => {});
			}else {
				throw e instanceof AxiosError ? new ApiAxiosError(e) : e;
			}

		}

	}

	public async getPackages() : Promise<Package[]>
	{
		return this.request('packages')
	}

}