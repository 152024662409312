import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "button__text" }


export default /*@__PURE__*/_defineComponent({
  __name: 'Button',
  props: {
    is: { default: 'a' },
    size: { default: 'normal' }
  },
  setup(__props: any) {


let props = __props;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(props).is), {
    class: _normalizeClass(["button", `button--${_unref(props).size}`])
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ]),
    _: 3
  }, 8, ["class"]))
}
}

})